import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router";
import { store } from "@/store/index";

import {
  ACCOUNT_TYPE,
  ACCOUNT_TYPE_SQUARE,
  ACCOUNT_STATUS,
  USER_CONFIRMED,
  PREVIOUS_ADMIN,
  ABORT_CONTROLLER,
  REFRESH_ABORT_CONTROLLER,
} from "@/windowConfig";
import { PERMISSIONS, DV_ENTRY } from "./config";

const IS_PROMOTER = ACCOUNT_TYPE === "promoter";

const loadView = (view, file) => () => {
  const VIEW = file ? file : view;

  return import(`@/views/${view}/${VIEW}.vue`);
};

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: loadView("Dashboard", `Dashboard_${ACCOUNT_TYPE.toLowerCase()}`),
  },
  {
    path: "/accounts/providers",
    name: "ProviderAccounts",
    meta: {
      value: "provider",
      key: "ProviderAccounts",
    },
    component: loadView("Accounts"),
  },
  {
    path: "/accounts/advertisers",
    name: "AdvertiserAccounts",
    meta: {
      value: "advertiser",
      key: "AdvertiserAccounts",
    },
    component: loadView("Accounts"),
  },
  {
    path: "/accounts/publishers",
    name: "PublisherAccounts",
    meta: {
      value: "publisher",
      key: "PublisherAccounts",
    },
    component: loadView("Accounts"),
  },
  {
    path: "/accounts/promoters",
    name: "PromoterAccounts",
    meta: {
      value: "promoter",
      key: "PromoterAccounts",
    },
    component: loadView("Accounts"),
  },
  {
    path: "/accounts/:providerId/:accountType/edit/:accountId",
    name: "AccountEdit",
    component: loadView("Accounts", "AccountEdit"),
    redirect: { name: "AccountEditCompany" },
    children: [
      {
        name: "AccountEditAdInventory",
        path: "ad-inventory",
        component: loadView("Settings", "AdInventory/AdInventory"),
      },
      {
        name: "AccountEditBilling",
        path: "billing",
        component: loadView("Settings", "Billing/Billing"),
      },
      {
        name: "AccountEditBranding",
        path: "branding",
        component: loadView("Settings", "Branding/Branding"),
      },
      {
        name: "AccountEditCompany",
        path: "company",
        component: loadView("Settings", "Company/Company"),
      },
      {
        name: "AccountEditLocalisation",
        path: "localisation",
        component: loadView("Settings", "Localisation/Localisation"),
      },
      {
        name: "AccountEditManagement",
        path: "management",
        component: loadView("Settings", "Management/Management"),
      },
      {
        name: "AccountEditPayments",
        path: "payments",
        component: loadView("Settings", "Payments/Payments"),
      },
      {
        name: "AccountEditUsers",
        path: "users",
        component: loadView("Settings", "Users/Users"),
      },
    ],
  },
  {
    path: "/ad-inventory",
    name: "AdInventory",
    // route level code-splitting
    // this generates a separate chunk (reports.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: loadView("AdInventory", false),
  },
  {
    path: "/creatives/banners",
    name: "Banners",
    component: loadView("Ads", "Banners/Banners"),
  },
  {
    path: "/creatives/product-feeds",
    name: "ProductFeeds",
    component: loadView("Ads", "Product feeds/ProductFeeds"),
  },
  {
    path: "/creatives/rich-media",
    name: "RichMedia",
    component: loadView("Ads", "Rich media/RichMedia"),
  },
  {
    path: "/creatives/text-links",
    name: "TextLinks",
    component: loadView("Ads", "Text links/TextLinks"),
  },
  {
    path: "/creatives/voucher-codes",
    name: "DealsAndVouchers",
    component: loadView("Ads", "Deals and vouchers/DealsAndVouchers"),
  },
  {
    path: "/downloads",
    name: "DownloadCenter",
    component: loadView("DownloadCenter"),
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: loadView("Marketplace"),
  },
  {
    path: "/notifications",
    name: "Notifications",
    component: loadView("Notifications"),
  },
  {
    path: "/notification-settings",
    name: "NotificationSettings",
    component: loadView("Notifications", "NotificationSettings"),
  },
  {
    path: "/offer/:offerId/",
    name: "OfferCreator",
    component: loadView("OfferCreator"),
    redirect: { name: "OfferCreatorGeneral" },
    children: [
      {
        path: "",
        name: "OfferCreatorNew",
        component: loadView("OfferCreator", "General/General"),
        meta: { key: "OfferCreatorNew" },
      },
      {
        path: "general",
        name: "OfferCreatorGeneral",
        component: loadView("OfferCreator", "General/General"),
        meta: { key: "OfferCreatorEdit" },
      },
      {
        path: "tracking",
        name: "OfferCreatorTracking",
        component: loadView("OfferCreator", "Tracking/Tracking"),
        meta: { key: "OfferCreatorEdit" },
      },
      {
        path: "add-ons",
        name: "OfferCreatorAddons",
        component: loadView("OfferCreator", "Addons/Addons"),
        meta: { key: "OfferCreatorEdit" },
      },
      {
        path: "terms",
        name: "OfferCreatorTerms",
        component: loadView("OfferCreator", "Terms/Terms"),
        meta: { key: "OfferCreatorEdit" },
      },
      {
        path: "targeting",
        name: "OfferCreatorTargeting",
        component: loadView("OfferCreator", "Targeting/Targeting"),
        meta: { key: "OfferCreatorEdit" },
      },
      {
        path: "price-segments",
        name: "OfferCreatorPriceSegments",
        component: loadView("OfferCreator", "PriceSegments/PriceSegments"),
        meta: { key: "OfferCreatorEdit" },
      },
    ],
  },
  {
    path: "/offers",
    name: "Offers",
    component: loadView("Offers"),
  },
  {
    path: "/payouts",
    name: "PayoutsListing",
    component: loadView("Payouts", "PayoutsListing"),
  },
  {
    path: "/postbacks",
    name: "Postbacks",
    component: loadView("Postbacks"),
  },
  {
    path: "/reports/clicks",
    name: "ClicksReport",
    component: loadView("Reports", "Clicks/Clicks"),
  },
  {
    path: "/reports/performance",
    name: "PerformanceReport",
    component: loadView("Reports", "Performance/Performance"),
  },
  {
    path: "/reports/touchpoints",
    name: "TouchpointsReport",
    component: loadView("Reports", "Touchpoints/Touchpoints"),
  },
  {
    path: "/reports/transactions",
    name: "TransactionsReport",
    component: loadView("Reports", "Transactions/Transactions"),
  },
  {
    path: "/invoices",
    name: "Invoices",
    meta: {
      documentType: ["INVOICE", "INVOICE_CORRECTION"],
      link: "/finance/#/documents/creator/INVOICE/new/edit",
      name: "invoice",
      title: "invoices",
      key: "Invoices",
    },
    component: loadView("Billing"),
  },
  {
    path: "/self-billing",
    name: "SelfBilling",
    meta: {
      documentType: ["CREDIT_NOTE", "SELF_BILLING_NOTE"],
      link: "/finance/#/documents/creator/CREDIT_NOTE/new/edit",
      name: "self billing",
      title: IS_PROMOTER ? "payments" : "selfBilling",
      key: "SelfBilling",
    },
    component: loadView("Billing"),
  },
  {
    path: "/bank-accounts",
    name: "BankAccounts",
    component: loadView("BankAccounts"),
  },
  {
    path: "/trades",
    name: "Trades",
    component: loadView("Trades"),
  },
  {
    path: "/settings",
    component: loadView("Settings"),
    redirect: "/settings/company",
    children: [
      {
        name: "SettingsAdInventory",
        path: "ad-inventory",
        component: loadView("Settings", "AdInventory/AdInventory"),
      },
      {
        name: "SettingsBilling",
        path: "billing",
        component: loadView("Settings", "Billing/Billing"),
      },
      {
        name: "SettingsBranding",
        path: "branding",
        component: loadView("Settings", "Branding/Branding"),
      },
      {
        name: "SettingsCompany",
        path: "company",
        component: loadView("Settings", "Company/Company"),
      },
      {
        name: "SettingsLocalisation",
        path: "localisation",
        component: loadView("Settings", "Localisation/Localisation"),
      },
      {
        name: "SettingsPayments",
        path: "payments",
        component: loadView("Settings", "Payments/Payments"),
      },
      {
        name: "SettingsUsers",
        path: "users",
        component: loadView("Settings", "Users/Users"),
      },
      {
        name: "SettingsFinance",
        path: "finance",
        component: loadView("Settings", "Finance/Finance"),
      },
    ],
  },
  {
    path: "/403",
    name: "Forbidden",
    component: loadView("403"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: loadView("404"),
  },
];

const routes_credentials = [
  {
    name: "Sign up",
    path: "/signup",
    component: loadView("Credentials", "Signup/Signup"),
    children: [
      {
        path: "",
        name: "Create account",
        meta: { step: 0 },
        component: loadView("Credentials", "Signup/Steps/CreateAccount"),
      },
      {
        path: "publisher-settings",
        redirect: "/signup",
      },
      {
        path: "publisher-settings*",
        redirect: "/signup",
      },
      {
        path: ":hash",
        alias: "confirm-email:hash",
        name: "Confirm email",
        meta: { step: 1 },
        component: loadView("Credentials", "Signup/Steps/ConfirmEmail"),
      },
    ],
  },
  {
    path: "/promoter/signup/:hash",
    name: "Promoter Sign up",
    component: loadView("Credentials", "PromoterSignup/PromoterSignup"),
  },
  {
    name: "Sign in",
    path: "/signin",
    component: loadView("Credentials", "Signin/Signin"),
  },
  {
    name: "Password reset",
    path: "/password-reset",
    component: loadView("Credentials", "PasswordReset/PasswordReset"),
  },
  {
    name: "New password",
    path: "/new-password/:token",
    component: loadView("Credentials", "NewPassword/NewPassword"),
  },
];

const routes_confirm = [
  {
    path: "/signup/publisher-settings",
    component: loadView("Credentials", "Signup/Signup"),
    children: [
      {
        path: "",
        name: "Traffic details",
        meta: { step: 2 },
        component: loadView("Credentials", "Signup/Steps/TrafficDetails"),
      },
      {
        path: "account-details",
        name: "Account details",
        meta: { step: 3 },
        component: loadView("Credentials", "Signup/Steps/AccountDetails"),
      },
      {
        path: "signup-summary",
        name: "Signup summary",
        component: loadView("Credentials", "Signup/Steps/Summary"),
      },
    ],
  },
  {
    path: "/signup",
    redirect: "/signup/publisher-settings",
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: loadView("404"),
  },
];

const ALLOW_USER = USER_CONFIRMED || PREVIOUS_ADMIN;

const router = createRouter({
  // mode: ACCOUNT_STATUS === 3 && ALLOW_USER ? "hash" : "history",
  history:
    ACCOUNT_STATUS === 3 && ALLOW_USER
      ? createWebHashHistory()
      : createWebHistory(),
  routes:
    ACCOUNT_STATUS === 3
      ? routes
      : [1, 2].includes(ACCOUNT_STATUS)
        ? routes_confirm
        : routes_credentials,
});

router.beforeEach((to, from, next) => {
  ABORT_CONTROLLER.abort();
  REFRESH_ABORT_CONTROLLER();

  if (ACCOUNT_STATUS === 3) {
    try {
      if (PERMISSIONS[to.name]) {
        if (PERMISSIONS[to.name].indexOf(ACCOUNT_TYPE_SQUARE) === -1)
          next({ name: "Forbidden" });
        else next();
      } else {
        next({ name: "NotFound" });
      }
    } catch (err) {
      console.warn(
        `${err}: Did you forget to set permissions for this route? ${to}`,
      );
    }
    if (ACCOUNT_TYPE === "PROMOTER") return;
    const DV_NAME = DV_ENTRY[to.name] ? DV_ENTRY[to.name] : "";
    store.dispatch("setCurrentViewName", DV_NAME);
    store.dispatch("setActiveRoute", to.path);
  } else {
    next();
  }
});

export default router;
