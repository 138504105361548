<template>
  <Sidebar v-if="active" />
  <RouterView :key="$route.meta?.key" />
  <UserEdit
    v-if="getCurrentUserEdit"
    :account-type="ACCOUNT_TYPE"
    @close="setUserEdit(false)" />
</template>

<script>
//config
import { ACCOUNT_IS_ACTIVE, ACCOUNT_TYPE } from "@/windowConfig";
import { defineAsyncComponent } from "vue";

//vuex
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    UserEdit: defineAsyncComponent(
      () => import("@/views/Settings/Users/Partials/UserEdit.vue"),
    ),
    Sidebar: defineAsyncComponent(
      () => import("@/components/TheSidebar/TheSidebar.vue"),
    ),
  },
  data() {
    return {
      ACCOUNT_TYPE,
      active: ACCOUNT_IS_ACTIVE,
      userSettingsOpen: false,
    };
  },
  computed: { ...mapGetters(["getCurrentUserEdit"]) },
  mounted() {
    this.$language.current = localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "en";
  },
  methods: {
    ...mapActions(["setUserEdit"]),
  },
};
</script>
